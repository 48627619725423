a, u {
    color: black;
    font-weight: bold;
    text-decoration: none;
}
html, body { 
    height: 100%;

}

ol {
    margin: 0;
    padding-left: 0;
    padding-bottom: 12px;
}

.question-box {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+98,000000+99&0+0,0.65+30 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 30%, rgba(0,0,0,0.65) 98%, rgba(0,0,0,0.65) 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 30%,rgba(0,0,0,0.65) 98%,rgba(0,0,0,0.65) 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 30%,rgba(0,0,0,0.65) 98%,rgba(0,0,0,0.65) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

}